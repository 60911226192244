<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.4"
            d="M21.6702 6.94942C21.3353 5.86095 20.7392 4.87093 19.9339 4.06566C19.1287 3.26039 18.1387 2.66432 17.0502 2.32942C15.4002 1.84942 14.2602 1.88942 13.4702 2.47942C13.0825 2.84883 12.7852 3.30263 12.6014 3.80558C12.4176 4.30853 12.3521 4.84708 12.4102 5.37942V7.86942C12.4102 10.3294 13.5302 11.5794 15.7302 11.5794H18.6002C19.1324 11.6363 19.6706 11.5703 20.1734 11.3865C20.6761 11.2027 21.13 10.9061 21.5002 10.5194C22.1102 9.73942 22.1602 8.60042 21.6702 6.94942Z"
            fill="white" />
        <path
            d="M18.9101 13.3609C18.7819 13.2136 18.6236 13.0954 18.446 13.0143C18.2683 12.9333 18.0754 12.8912 17.8801 12.8909H14.3031C13.4572 12.8903 12.6461 12.5541 12.048 11.956C11.4499 11.3578 11.1136 10.5468 11.1131 9.70088V6.12088C11.1128 5.92559 11.0707 5.73264 10.9896 5.55497C10.9086 5.37731 10.7904 5.21907 10.6431 5.09088C10.4985 4.9628 10.3283 4.8671 10.1438 4.81018C9.95922 4.75327 9.76465 4.73646 9.57308 4.76088C8.39845 4.91686 7.26784 5.31026 6.25009 5.91713C5.23234 6.52399 4.34874 7.33163 3.65308 8.29088C2.79944 9.4668 2.25643 10.8392 2.07437 12.2808C1.89232 13.7225 2.07711 15.1868 2.61161 16.538C3.14612 17.8892 4.01305 19.0837 5.13207 20.0106C6.25109 20.9376 7.58599 21.5671 9.01308 21.8409C9.55645 21.9486 10.1091 22.0022 10.6631 22.0009C12.4795 22.0046 14.2493 21.4263 15.7131 20.3509C16.6723 19.6552 17.48 18.7716 18.0868 17.7539C18.6937 16.7361 19.0871 15.6055 19.2431 14.4309C19.2678 14.2391 19.2509 14.0442 19.1934 13.8595C19.1359 13.6749 19.0393 13.5048 18.9101 13.3609Z"
            fill="white" />
    </svg>
</template>

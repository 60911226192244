import axios from 'axios';
import { useStore } from '@/store/loading.js';
import { useToastStore } from '@/store/toast';
import { useCookies } from 'vue3-cookies';
import router from '@/router';
import baseURL from '@/utils/base-url-config';

const { cookies } = useCookies();

const GA_PROPERTY_ID = '442154687';
const GA_ENDPOINT = `https://analyticsdata.googleapis.com/v1beta/properties/${GA_PROPERTY_ID}/`;

const httpClient = axios.create({
    baseURL: baseURL,
    headers: {
        'X-CLIENT-SECRET': process.env.VUE_APP_NOT_SECRET_CODE,
    },
});

export const httpGa = axios.create({
    baseURL: GA_ENDPOINT,
});

export const httpClientWithoutLoading = axios.create({
    baseURL: baseURL,
    headers: {
        'X-CLIENT-SECRET': process.env.VUE_APP_NOT_SECRET_CODE,
    },
});

httpGa.interceptors.request.use(
    config => {
        const ACCESS_KEY = localStorage.getItem('ga_access_key');
        const HEADER = {
            Authorization: `Bearer ${ACCESS_KEY}`,
            'Content-Type': 'application/json',
        };
        config.headers = HEADER;

        const store = useStore();
        numberOfAjaxCAllPending++;
        store.mutationer({ isLoading: true });
        store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
        return config;
    },
    error => {
        throw error;
    }
);

httpGa.interceptors.response.use(
    response => {
        const store = useStore();

        numberOfAjaxCAllPending--;
        store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
        // eslint-disable-next-line eqeqeq
        if (numberOfAjaxCAllPending == 0) {
            store.mutationer({ isLoading: false });
        }

        return response;
    },
    error => {
        const store = useStore();

        if (numberOfAjaxCAllPending > 0) numberOfAjaxCAllPending--;
        store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
        store.mutationer({ isLoading: false });

        if (
            error?.response?.data?.message ||
            error?.response?.data?.result?.message ||
            error?.response?.data?.data?.message
        )
            useToastStore().show({
                message:
                    error?.response?.data?.message ||
                    error?.response?.data?.result?.message ||
                    error?.response?.data?.data?.message,
                type: 'error',
            });

        throw error;
    }
);

httpClientWithoutLoading.interceptors.request.use(
    config => {
        const store = useStore();

        const token = cookies.get('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers['X-CLIENT-ID'] =
            store && store.CLIENT_ID ? +store.CLIENT_ID : null;

        return config;
    },
    error => {
        throw error;
    }
);

// eslint-disable-next-line no-unused-vars
let numberOfAjaxCAllPending = 0;

httpClient.interceptors.request.use(
    config => {
        const store = useStore();
        numberOfAjaxCAllPending++;
        store.mutationer({ isLoading: true });
        store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;

        const token = cookies.get('token');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers['X-CLIENT-ID'] =
            store && store.CLIENT_ID ? +store.CLIENT_ID : null;

        return config;
    },
    error => {
        throw error;
    }
);

httpClient.interceptors.response.use(
    response => {
        const store = useStore();
        const toastStore = useToastStore();

        numberOfAjaxCAllPending--;
        store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
        // eslint-disable-next-line eqeqeq
        if (numberOfAjaxCAllPending == 0) {
            store.mutationer({ isLoading: false });
        }

        if (
            (response.config.method === 'post' ||
                response.config.method === 'patch' ||
                response.config.method === 'put' ||
                response.config.method === 'delete') &&
            (response.status === 200 || response.status === 201)
        ) {
            setTimeout(() => {
                if (response.data.message)
                    toastStore.show({
                        message: response.data.message || 'Success',
                        type: 'success',
                        requestMethod: response.config.method,
                    });
            }, 0);
        }

        if (response.data) return response.data;
        else return response;
    },
    error => {
        const store = useStore();

        if (numberOfAjaxCAllPending > 0) numberOfAjaxCAllPending--;
        store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
        store.mutationer({ isLoading: false });

        if (
            error?.response?.data?.message ||
            error?.response?.data?.result?.message ||
            error?.response?.data?.data?.message
        )
            useToastStore().show({
                message:
                    error?.response?.data?.message ||
                    error?.response?.data?.result?.message ||
                    error?.response?.data?.data?.message,
                type: 'error',
            });

        if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
        ) {
            cookies.remove('token');
            router.push({ name: 'login' });
        }

        throw error;
    }
);

export default httpClient;

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            d="M12 22.6308C11.33 22.6308 10.65 22.4808 10.12 22.1708L4.61999 19.0008C2.37999 17.4908 2.23999 17.2608 2.23999 14.8908V9.11078C2.23999 6.74078 2.36999 6.51078 4.56999 5.02078L10.11 1.82078C11.16 1.21078 12.81 1.21078 13.86 1.82078L19.38 5.00078C21.62 6.51078 21.76 6.74078 21.76 9.11078V14.8808C21.76 17.2508 21.63 17.4808 19.43 18.9708L13.89 22.1708C13.35 22.4808 12.67 22.6308 12 22.6308ZM12 2.87078C11.58 2.87078 11.17 2.95078 10.88 3.12078L5.37999 6.30078C3.74999 7.40078 3.74999 7.40078 3.74999 9.11078V14.8808C3.74999 16.5908 3.74999 16.5908 5.41999 17.7208L10.88 20.8708C11.47 21.2108 12.54 21.2108 13.13 20.8708L18.63 17.6908C20.25 16.5908 20.25 16.5908 20.25 14.8808V9.11078C20.25 7.40078 20.25 7.40078 18.58 6.27078L13.12 3.12078C12.83 2.95078 12.42 2.87078 12 2.87078Z"
            fill="white" />
        <path
            opacity="0.4"
            d="M12 15.75C9.93 15.75 8.25 14.07 8.25 12C8.25 9.93 9.93 8.25 12 8.25C14.07 8.25 15.75 9.93 15.75 12C15.75 14.07 14.07 15.75 12 15.75ZM12 9.75C10.76 9.75 9.75 10.76 9.75 12C9.75 13.24 10.76 14.25 12 14.25C13.24 14.25 14.25 13.24 14.25 12C14.25 10.76 13.24 9.75 12 9.75Z"
            fill="white" />
    </svg>
</template>

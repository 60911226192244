<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            d="M20.5 16.75H18.25V14.5C18.25 14.3011 18.171 14.1103 18.0303 13.9697C17.8897 13.829 17.6989 13.75 17.5 13.75C17.3011 13.75 17.1103 13.829 16.9697 13.9697C16.829 14.1103 16.75 14.3011 16.75 14.5V16.75H14.5C14.3011 16.75 14.1103 16.829 13.9697 16.9697C13.829 17.1103 13.75 17.3011 13.75 17.5C13.75 17.6989 13.829 17.8897 13.9697 18.0303C14.1103 18.171 14.3011 18.25 14.5 18.25H16.75V20.5C16.75 20.6989 16.829 20.8897 16.9697 21.0303C17.1103 21.171 17.3011 21.25 17.5 21.25C17.6989 21.25 17.8897 21.171 18.0303 21.0303C18.171 20.8897 18.25 20.6989 18.25 20.5V18.25H20.5C20.6989 18.25 20.8897 18.171 21.0303 18.0303C21.171 17.8897 21.25 17.6989 21.25 17.5C21.25 17.3011 21.171 17.1103 21.0303 16.9697C20.8897 16.829 20.6989 16.75 20.5 16.75Z"
            fill="#00094F" />
        <path
            d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
            fill="#00094F" />
        <path
            d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
            fill="#00094F" />
        <path
            opacity="0.4"
            d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
            fill="#00094F" />
    </svg>
</template>

import { httpClientWithoutLoading } from '../index';

const END_POINT = 'notification';

const getNotifications = () => {
    return httpClientWithoutLoading.get(END_POINT);
};

const getNotificationsInterval = () => {
    return httpClientWithoutLoading.get(END_POINT + '/interval');
};

const markNotificationAsRead = id => {
    if (id === null) {
        return httpClientWithoutLoading.patch(END_POINT + '/as-read');
    }
    return httpClientWithoutLoading.patch(
        END_POINT + '/as-read',
        {},
        {
            params: { id },
        }
    );
};

export { getNotifications, getNotificationsInterval, markNotificationAsRead };

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.67001 2.5V14.47C3.67001 15.45 4.13001 16.38 4.92001 16.97L10.13 20.87C11.24 21.7 12.77 21.7 13.88 20.87L19.09 16.97C19.88 16.38 20.34 15.45 20.34 14.47V2.5H3.67001Z"
            stroke="#00094F"
            stroke-width="1.5"
            stroke-miterlimit="10" />
        <path
            d="M2 2.5H22"
            stroke="#00094F"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round" />
        <path
            opacity="0.4"
            d="M8 8H16"
            stroke="#00094F"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            opacity="0.4"
            d="M8 13H16"
            stroke="#00094F"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>

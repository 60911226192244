let baseURL = '';

if (process.env.VUE_APP_MODE === 'development') {
    // Development mode
    baseURL = process.env.VUE_APP_BASE_URL;
} else {
    // Production mode
    const currentUrl = window.location.href;
    if (currentUrl.startsWith('https://develop')) {
        // If the app is being served from a URL that starts with "develop"
        baseURL = process.env.VUE_APP_BASE_URL_DEV;
    } else {
        // Otherwise use the production URL
        baseURL = process.env.VUE_APP_BASE_URL_PRO;
    }
}

export default baseURL;

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            d="M21.6702 6.94942C21.3353 5.86095 20.7392 4.87093 19.9339 4.06566C19.1287 3.26039 18.1387 2.66432 17.0502 2.32942C15.4002 1.84942 14.2602 1.88942 13.4702 2.47942C13.0825 2.84883 12.7852 3.30263 12.6014 3.80558C12.4176 4.30853 12.3521 4.84708 12.4102 5.37942V7.86942C12.4102 10.3294 13.5302 11.5794 15.7302 11.5794H18.6002C19.1324 11.6363 19.6706 11.5703 20.1734 11.3865C20.6761 11.2027 21.13 10.9061 21.5002 10.5194C22.1102 9.73942 22.1602 8.60042 21.6702 6.94942Z"
            fill="#00094F" />
        <path
            opacity="0.4"
            d="M18.9101 13.3589C18.7819 13.2116 18.6236 13.0934 18.446 13.0124C18.2683 12.9313 18.0754 12.8892 17.8801 12.8889H14.3031C13.4572 12.8884 12.6461 12.5521 12.048 11.954C11.4499 11.3559 11.1136 10.5448 11.1131 9.69892V6.11892C11.1128 5.92364 11.0707 5.73068 10.9896 5.55302C10.9086 5.37536 10.7904 5.21711 10.6431 5.08892C10.4985 4.96085 10.3283 4.86514 10.1438 4.80823C9.95922 4.75131 9.76465 4.7345 9.57308 4.75892C8.39845 4.91491 7.26784 5.30831 6.25009 5.91517C5.23234 6.52204 4.34874 7.32968 3.65308 8.28892C2.79944 9.46485 2.25643 10.8372 2.07437 12.2789C1.89232 13.7205 2.07711 15.1848 2.61161 16.536C3.14612 17.8873 4.01305 19.0817 5.13207 20.0087C6.25109 20.9357 7.58599 21.5652 9.01308 21.8389C9.55645 21.9466 10.1091 22.0002 10.6631 21.9989C12.4795 22.0026 14.2493 21.4244 15.7131 20.3489C16.6723 19.6533 17.48 18.7697 18.0868 17.7519C18.6937 16.7342 19.0871 15.6036 19.2431 14.4289C19.2678 14.2371 19.2509 14.0422 19.1934 13.8576C19.1359 13.6729 19.0393 13.5028 18.9101 13.3589Z"
            fill="#00094F" />
    </svg>
</template>

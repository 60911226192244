<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            opacity="0.4"
            d="M18.3199 12.7504H14.9999C12.5099 12.7504 11.2499 11.3404 11.2499 8.55045V5.68045C11.1846 5.08001 11.2568 4.47257 11.4613 3.90424C11.6658 3.33591 11.997 2.82164 12.4299 2.40045C13.3199 1.74045 14.5999 1.69045 16.4899 2.24045C17.7339 2.61781 18.8657 3.29616 19.785 4.2154C20.7042 5.13465 21.3826 6.26641 21.7599 7.51045C22.3099 9.39045 22.2599 10.6804 21.5999 11.5604C21.1807 11.9965 20.667 12.3306 20.0983 12.5369C19.5297 12.7432 18.9212 12.8163 18.3199 12.7504ZM14.2799 3.36345C13.949 3.34095 13.6195 3.42506 13.3399 3.60345C12.9499 3.89345 12.7599 4.57345 12.7599 5.68345V8.56345C12.7599 10.8034 13.6199 11.2634 15.0099 11.2634H18.3299C19.4299 11.2634 20.1099 11.0734 20.4099 10.6834C20.7599 10.2234 20.7299 9.30345 20.3399 7.95345C20.0279 6.95422 19.4782 6.04556 18.738 5.30535C17.9978 4.56515 17.0891 4.01547 16.0899 3.70345C15.5063 3.50276 14.8966 3.38822 14.2799 3.36345Z"
            fill="#00094F" />
        <path
            d="M11.0699 22.7505C8.63309 22.747 6.28423 21.8396 4.4781 20.2037C2.67197 18.5679 1.53706 16.3201 1.29311 13.8956C1.04916 11.471 1.71352 9.04215 3.15757 7.0793C4.60161 5.11646 6.72261 3.75925 9.10989 3.27046C9.3046 3.23174 9.50673 3.27075 9.67306 3.37914C9.83938 3.48753 9.95669 3.65669 9.99989 3.85046C10.0386 4.04517 9.9996 4.2473 9.89121 4.41362C9.78282 4.57994 9.61366 4.69725 9.41989 4.74046C7.94287 5.04409 6.57573 5.7423 5.46383 6.76087C4.35193 7.77943 3.53683 9.08027 3.10519 10.5251C2.67355 11.9699 2.64151 13.5047 3.01247 14.9662C3.38343 16.4278 4.14352 17.7615 5.21194 18.8256C6.28036 19.8897 7.61717 20.6444 9.08023 21.0094C10.5433 21.3744 12.0779 21.3361 13.521 20.8986C14.964 20.4611 16.2615 19.6407 17.2756 18.5247C18.2896 17.4087 18.9823 16.0387 19.2799 14.5605C19.3183 14.3655 19.4327 14.1938 19.5977 14.0832C19.6794 14.0284 19.7711 13.9903 19.8676 13.9709C19.964 13.9516 20.0634 13.9514 20.1599 13.9705C20.2564 13.9895 20.3482 14.0274 20.4301 14.0819C20.512 14.1364 20.5824 14.2065 20.6371 14.2883C20.6919 14.37 20.7301 14.4617 20.7494 14.5581C20.7688 14.6546 20.7689 14.7539 20.7499 14.8505C20.2985 17.0834 19.0873 19.0909 17.3224 20.5312C15.5575 21.9716 13.348 22.7558 11.0699 22.7505Z"
            fill="#00094F" />
    </svg>
</template>

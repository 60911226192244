<template>
    <header class="wrapper">
        <nav>
            <ul>
                <li
                    v-for="(crumb, index) in breadcrumb.breadcrumbs"
                    :key="`${crumb}-${index}`"
                    :class="{
                        'last-segment':
                            index === breadcrumb.breadcrumbs.length - 1,
                    }">
                    <IconNavChevron
                        v-if="index > 0"
                        class="nav-chevron-icon"
                        :outlineColor="
                            index !== breadcrumb.breadcrumbs.length - 1
                                ? 'rgba(0 9 79 / 0.4)'
                                : 'rgba(0 9 79 / 1)'
                        "
                        :chevronColor="
                            index !== breadcrumb.breadcrumbs.length - 1
                                ? 'rgba(0 9 79 / 0.4)'
                                : 'rgba(0 9 79 / 1)'
                        " />
                    <router-link
                        v-if="crumb.to"
                        :to="crumb.to"
                        >{{ crumb.text }}</router-link
                    >
                    <span v-else-if="!crumb.text.startsWith(':')">{{
                        crumb.text
                    }}</span>
                </li>
            </ul>
        </nav>
        <button
            class="menu-icon"
            @click="openSidebar">
            <IconHamburgerMenu />
        </button>
        <div class="separator"></div>
        <div class="cal-notif">
            <div class="calendar">
                <IconCalendar class="calendar-icon" />
                <span>{{ getCurrentDate() }}</span>
            </div>
            <div
                class="notification"
                :class="{ disc: isThereAnyUnreadNotif }">
                <button @click="toggleNotification">
                    <i
                        style="font-size: 24px; color: #00094f"
                        class="iconsax"
                        icon-name="bell-1"></i>
                </button>
                <NofiticationsPopup
                    class="popup"
                    :class="isNotificationOpen ? 'block' : 'hidden'"
                    :notifs="notifications"
                    @mark-as-read="markNotifAsRead" />
            </div>
        </div>
        <div class="separator"></div>
        <div
            class="user cursor-pointer items-center"
            @click="$router.push({ name: 'profile' })">
            <div class="avatar">
                <img
                    v-if="profile"
                    :src="profile" />
            </div>
            <div class="data">
                <div class="name">
                    {{
                        store.user.full_name
                            ? capitalizeFirstLetter(store.user.full_name)
                            : '-'
                    }}
                </div>
                <div class="role">Admin</div>
            </div>
        </div>
    </header>
</template>

<script setup>
import IconNavChevron from '../icons/IconNavChecron';
import IconCalendar from '../icons/IconCalendar';
import IconHamburgerMenu from '../icons/IconHamburgerMenu';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from '@/store/loading.js';
import { useBreadcrumbStore } from '@/store/breadcrumb';
import NofiticationsPopup from './NofiticationsPopup.vue';
import {
    getNotifications,
    getNotificationsInterval,
    markNotificationAsRead,
} from '@/services/axios/notification/notifications.service';

const breadcrumb = useBreadcrumbStore();

const store = useStore();
const emits = defineEmits(['open-sidebar']);
const route = useRoute();

const openSidebar = () => {
    emits('open-sidebar');
};
const user = ref({});
// const profile = ref('')

const notificationsInterval = ref(null);

onMounted(async () => {
    try {
        const u = localStorage.getItem('user');
        store.user = JSON.parse(u);
        if (u) {
            user.value = store.user;
            const img = await loadImage(
                user.value.avatar,
                'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png'
            );
            profile.value = img;
        }
        // NOTIFICATION
        await fetchNotifications();

        // every 60 seconds call getNotificationsInterval
        notificationsInterval.value = setInterval(async () => {
            const newNotifs = await getNotificationsInterval();
            // add newNotifs to beginning of notifications array
            notifications.value = [
                ...newNotifs.data?.data,
                ...notifications.value,
            ];
        }, 60000);
    } catch (error) {
        console.error(error);
    }
});

onUnmounted(() => {
    clearInterval(notificationsInterval.value);
});

function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

async function loadImage(imageUrl, defaultUrl) {
    return await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(imageUrl);
        img.onerror = () => resolve(defaultUrl);
        img.src = imageUrl;
    });
}

const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'short' });
    return `${day} ${month}`;
};

const profile = computed(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    user.value = store.user;
    return (
        user.value.avatar ||
        'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png'
    );
});

// NOTIFICATION
const notifications = ref([]);
const isNotificationOpen = ref(false);

async function fetchNotifications() {
    const fetchedNotifications = await getNotifications();
    notifications.value = fetchedNotifications.data?.data;
}

function markNotifAsRead(id) {
    // update ui
    // if id === null, mark all as read
    if (id === null) {
        notifications.value.forEach(n => {
            n.read_at = new Date().toISOString();
        });
    } else {
        const notif = notifications.value.find(n => n.id === id);
        notif.read_at = new Date().toISOString();
    }
    // update server
    markNotificationAsRead(id);
}

const isThereAnyUnreadNotif = computed(() => {
    return notifications.value.some(n => n.read_at === null);
});

function toggleNotification() {
    isNotificationOpen.value = !isNotificationOpen.value;
}

watch(route, () => {
    isNotificationOpen.value = false;
});
</script>

<style scoped lang="scss">
header.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: rgb(var(--gray-100));
    padding: 20px 16px;

    @media (width > 1024px) {
        padding: 28px 24px 24px;
    }

    @media (width > 1200px) {
        padding: 56px 64px 24px;
    }

    nav {
        display: none;
        margin-right: auto;

        @media (width > 1024px) {
            display: block;
        }

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            gap: 18px;

            li {
                display: flex;
                align-items: center;
                gap: 18px;

                @media (width > 1024px) {
                    gap: 14px;
                }

                @media (width > 1280px) {
                    gap: 18px;
                }

                span,
                a {
                    font-size: 14px;
                    line-height: 20px;
                    color: #8c96a5;
                }

                .nav-chevron-icon {
                    width: 20px;
                }
            }
            .last-segment span {
                font-weight: 700;
                color: #00094f;
            }
        }
    }

    .menu-icon {
        display: block;

        @media (width > 1024px) {
            display: none;
        }
    }

    .separator {
        width: 1px;
        height: 24px;
        background-color: rgb(var(--gray-200));
    }

    .cal-notif {
        display: flex;
        align-items: center;
        gap: 24px;

        @media (width > 1024px) {
            gap: 0;
        }

        .calendar {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            padding: 0 18px;
            border-radius: 1000px;
            gap: 10px;
            background-color: rgb(var(--white));

            @media (width > 1024px) {
                margin: 0 24px;
            }

            .calendar-icon {
                width: 24px;
            }

            span {
                font-size: 14px;
                color: rgb(var(--black));
            }
        }

        .notification {
            position: relative;
            @media (width > 1024px) {
                margin-right: 24px;
            }
            &.disc::before {
                content: '';
                position: absolute;
                top: 2px;
                right: 2px;
                width: 8px;
                height: 8px;
                border-radius: 100px;
                background-color: rgb(var(--blue));
                z-index: 10;
                border: 1px solid rgb(var(--gray-100));
            }
            .popup {
                position: absolute;
                top: 55px;
                left: -320px;
            }
        }
    }

    .user {
        display: flex;
        flex-shrink: 0;

        @media (width > 1024px) {
            margin-left: 24px;
        }

        .avatar {
            width: 32px;
            height: 32px;
            margin-right: 16px;
            border-radius: 100px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 3px;
                right: 0px;
                width: 6px;
                height: 6px;
                border-radius: 100px;
                background-color: rgb(var(--green));
                z-index: 10;
                border: 1px solid rgb(var(--gray-100));
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 100px;
            }
        }

        .data {
            display: none;
            font-size: 14px;

            @media (width > 1024px) {
                display: block;
            }

            .name {
                color: rgb(var(--black));
            }

            .role {
                color: rgb(var(--gray-400));
            }
        }
    }
}
</style>

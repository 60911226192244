<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            d="M6.73 19.7C6.90538 19.4943 7.12582 19.3319 7.37419 19.2252C7.62257 19.1186 7.89218 19.0707 8.16209 19.0852C8.43199 19.0997 8.69491 19.1763 8.93042 19.3089C9.16593 19.4415 9.36768 19.6267 9.52 19.85L10.53 21.2C10.6871 21.4461 10.9037 21.6487 11.1597 21.789C11.4158 21.9293 11.703 22.0029 11.995 22.0029C12.287 22.0029 12.5742 21.9293 12.8303 21.789C13.0863 21.6487 13.3029 21.4461 13.46 21.2L14.47 19.85C14.6223 19.6267 14.8241 19.4415 15.0596 19.3089C15.2951 19.1763 15.558 19.0997 15.8279 19.0852C16.0978 19.0707 16.3674 19.1186 16.6158 19.2252C16.8642 19.3319 17.0846 19.4943 17.26 19.7C19.04 21.6 20.49 20.97 20.49 18.31V7.04C20.5 3.01 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.97 4.96 21.59 6.73 19.7Z"
            fill="#00094F" />
        <path
            opacity="0.5"
            d="M16.23 11.75H10.73C10.5315 11.7487 10.3415 11.6693 10.2011 11.5289C10.0608 11.3885 9.98132 11.1985 9.98001 11C9.98132 10.8015 10.0608 10.6115 10.2011 10.4711C10.3415 10.3307 10.5315 10.2513 10.73 10.25H16.23C16.4285 10.2513 16.6185 10.3307 16.7589 10.4711C16.8993 10.6115 16.9787 10.8015 16.98 11C16.9787 11.1985 16.8993 11.3885 16.7589 11.5289C16.6185 11.6693 16.4285 11.7487 16.23 11.75Z"
            fill="white" />
        <path
            d="M16.23 7.75H10.73C10.5315 7.74869 10.3415 7.66925 10.2011 7.52888C10.0608 7.38851 9.98132 7.19851 9.98001 7C9.98132 6.80149 10.0608 6.61149 10.2011 6.47112C10.3415 6.33075 10.5315 6.25131 10.73 6.25H16.23C16.4285 6.25131 16.6185 6.33075 16.7589 6.47112C16.8993 6.61149 16.9787 6.80149 16.98 7C16.9787 7.19851 16.8993 7.38851 16.7589 7.52888C16.6185 7.66925 16.4285 7.74869 16.23 7.75Z"
            fill="white" />
        <path
            d="M7.78 8C7.58222 8 7.38888 7.94135 7.22443 7.83147C7.05998 7.72159 6.93181 7.56541 6.85612 7.38268C6.78043 7.19996 6.76063 6.99889 6.79921 6.80491C6.8378 6.61093 6.93304 6.43275 7.07289 6.29289C7.21274 6.15304 7.39093 6.0578 7.58491 6.01922C7.77889 5.98063 7.97996 6.00043 8.16268 6.07612C8.34541 6.15181 8.50159 6.27998 8.61147 6.44443C8.72135 6.60888 8.78 6.80222 8.78 7C8.78 7.26522 8.67464 7.51957 8.48711 7.70711C8.29957 7.89464 8.04522 8 7.78 8Z"
            fill="white" />
        <path
            opacity="0.5"
            d="M7.78 12C7.58222 12 7.38888 11.9414 7.22443 11.8315C7.05998 11.7216 6.93181 11.5654 6.85612 11.3827C6.78043 11.2 6.76063 10.9989 6.79921 10.8049C6.8378 10.6109 6.93304 10.4327 7.07289 10.2929C7.21274 10.153 7.39093 10.0578 7.58491 10.0192C7.77889 9.98063 7.97996 10.0004 8.16268 10.0761C8.34541 10.1518 8.50159 10.28 8.61147 10.4444C8.72135 10.6089 8.78 10.8022 8.78 11C8.78 11.2652 8.67464 11.5196 8.48711 11.7071C8.29957 11.8946 8.04522 12 7.78 12Z"
            fill="white" />
    </svg>
</template>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            d="M12 22.75C11.5945 22.7405 11.1969 22.6361 10.8392 22.445C10.4814 22.254 10.1734 21.9817 9.94 21.65L8.93 20.3C8.83992 20.1703 8.72251 20.0619 8.58602 19.9824C8.44953 19.9029 8.29729 19.8543 8.14 19.84C7.98179 19.8388 7.82511 19.8711 7.68032 19.9349C7.53552 19.9987 7.4059 20.0924 7.3 20.21L6.73 19.7L7.28 20.21C5.84 21.75 4.73 21.63 4.2 21.42C3.67 21.21 2.75 20.52 2.75 18.3V7.04C2.75 2.6 4.03 1.25 8.22 1.25H15.78C19.97 1.25 21.25 2.6 21.25 7.04V18.3C21.25 20.51 20.34 21.2 19.8 21.42C19.26 21.64 18.17 21.75 16.72 20.21C16.6172 20.0861 16.4867 19.988 16.3391 19.9237C16.1914 19.8595 16.0308 19.8308 15.87 19.84C15.7113 19.8542 15.5576 19.9026 15.4194 19.9821C15.2813 20.0615 15.1621 20.17 15.07 20.3L14.06 21.65C13.8266 21.9817 13.5186 22.254 13.1608 22.445C12.8031 22.6361 12.4055 22.7405 12 22.75ZM8.08 18.33H8.21C8.58654 18.3562 8.95274 18.4646 9.28277 18.6478C9.6128 18.8309 9.8986 19.0843 10.12 19.39L11.13 20.74C11.22 20.8888 11.347 21.0119 11.4985 21.0973C11.65 21.1828 11.821 21.2276 11.995 21.2276C12.169 21.2276 12.34 21.1828 12.4915 21.0973C12.643 21.0119 12.77 20.8888 12.86 20.74L13.87 19.39C14.089 19.0798 14.3755 18.8233 14.7079 18.6397C15.0403 18.4561 15.4099 18.3502 15.7891 18.33C16.1683 18.3098 16.5471 18.3758 16.8971 18.523C17.2471 18.6702 17.5593 18.8948 17.81 19.18C18.57 19.99 19.06 20.09 19.24 20.02C19.48 19.92 19.74 19.34 19.74 18.3V7.04C19.74 3.43 19.11 2.75 15.77 2.75H8.22C4.88 2.75 4.25 3.43 4.25 7.04V18.3C4.25 19.35 4.51 19.93 4.75 20.02C4.92 20.09 5.42 19.99 6.18 19.18C6.42354 18.9182 6.71725 18.708 7.04365 18.562C7.37006 18.416 7.72249 18.3371 8.08 18.33Z"
            fill="#00094F" />
        <path
            opacity="0.4"
            d="M16.23 11.75H10.73C10.5315 11.7487 10.3415 11.6693 10.2011 11.5289C10.0607 11.3885 9.98129 11.1985 9.97998 11C9.98129 10.8015 10.0607 10.6115 10.2011 10.4711C10.3415 10.3307 10.5315 10.2513 10.73 10.25H16.23C16.4285 10.2513 16.6185 10.3307 16.7589 10.4711C16.8992 10.6115 16.9787 10.8015 16.98 11C16.9787 11.1985 16.8992 11.3885 16.7589 11.5289C16.6185 11.6693 16.4285 11.7487 16.23 11.75Z"
            fill="#00094F" />
        <path
            opacity="0.4"
            d="M16.23 7.75H10.73C10.5315 7.74869 10.3415 7.66925 10.2011 7.52888C10.0607 7.38851 9.98129 7.19851 9.97998 7C9.98129 6.80149 10.0607 6.61149 10.2011 6.47112C10.3415 6.33075 10.5315 6.25131 10.73 6.25H16.23C16.4285 6.25131 16.6185 6.33075 16.7589 6.47112C16.8992 6.61149 16.9787 6.80149 16.98 7C16.9787 7.19851 16.8992 7.38851 16.7589 7.52888C16.6185 7.66925 16.4285 7.74869 16.23 7.75Z"
            fill="#00094F" />
        <path
            opacity="0.4"
            d="M7.78003 8C7.58225 8 7.38891 7.94135 7.22446 7.83147C7.06001 7.72159 6.93184 7.56541 6.85615 7.38268C6.78046 7.19996 6.76066 6.99889 6.79924 6.80491C6.83783 6.61093 6.93307 6.43275 7.07292 6.29289C7.21278 6.15304 7.39096 6.0578 7.58494 6.01922C7.77892 5.98063 7.97999 6.00043 8.16271 6.07612C8.34544 6.15181 8.50162 6.27998 8.6115 6.44443C8.72138 6.60888 8.78003 6.80222 8.78003 7C8.78003 7.26522 8.67467 7.51957 8.48714 7.70711C8.2996 7.89464 8.04525 8 7.78003 8Z"
            fill="#00094F" />
        <path
            opacity="0.4"
            d="M7.78003 12C7.58225 12 7.38891 11.9414 7.22446 11.8315C7.06001 11.7216 6.93184 11.5654 6.85615 11.3827C6.78046 11.2 6.76066 10.9989 6.79924 10.8049C6.83783 10.6109 6.93307 10.4327 7.07292 10.2929C7.21278 10.153 7.39096 10.0578 7.58494 10.0192C7.77892 9.98063 7.97999 10.0004 8.16271 10.0761C8.34544 10.1518 8.50162 10.28 8.6115 10.4444C8.72138 10.6089 8.78003 10.8022 8.78003 11C8.78003 11.2652 8.67467 11.5196 8.48714 11.7071C8.2996 11.8946 8.04525 12 7.78003 12Z"
            fill="#00094F" />
    </svg>
</template>

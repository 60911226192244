import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import AppToast from '@/components/base/AppToast.vue';
import IconToastSuccess from '@/components/icons/toast-icons/IconToastSuccess.vue';
import IconToastError from '@/components/icons/toast-icons/IconToastError.vue';
import IconToastWarning from '@/components/icons/toast-icons/IconToastWarning.vue';

export const useToastStore = defineStore({
    id: 'toast',
    state: () => ({
        undoFunction: () => {},
    }),
    actions: {
        show({ message, type, requestMethod }) {
            const icon =
                type === 'success'
                    ? IconToastSuccess
                    : type === 'error'
                      ? IconToastError
                      : IconToastWarning;

            toast(AppToast, {
                theme: 'dark',
                closeOnClick: false,
                data: {
                    message,
                    requestMethod,
                },
                position: toast.POSITION.BOTTOM_LEFT,
                hideProgressBar: true,
                closeButton: false,
                type,
                icon: icon,
            });
        },
        setUndoFunction(undoFunction) {
            this.undoFunction = undoFunction;
        },
        async runUndoFunction() {
            this.undoFunction();
        },
    },
});

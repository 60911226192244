import { httpClientWithoutLoading } from '..';

import { httpGa } from '..';

export function gaGetAccessKey() {
    return httpClientWithoutLoading.get('google-analytics/access-token');
}

// Pass either projectId or organizationId, not both
export function gaGetUserAccessReport({ projectId, organizationId }) {
    return httpGa.post(':runReport', {
        dateRanges: [
            {
                startDate: '2024-04-01',
                endDate: 'today',
            },
        ],
        dimensions: [{ name: 'city' }],
        dimensionFilter: {
            andGroup: {
                expressions: [
                    {
                        notExpression: {
                            filter: {
                                fieldName: 'city',
                                stringFilter: {
                                    matchType: 'EXACT',
                                    value: '',
                                    caseSensitive: true,
                                },
                            },
                        },
                    },
                    {
                        notExpression: {
                            filter: {
                                fieldName: 'city',
                                stringFilter: {
                                    matchType: 'EXACT',
                                    value: '(not set)',
                                    caseSensitive: true,
                                },
                            },
                        },
                    },
                    {
                        filter: {
                            fieldName: projectId
                                ? 'customEvent:project_id'
                                : organizationId
                                  ? 'customEvent:organization_id'
                                  : null,
                            stringFilter: {
                                matchType: 'EXACT',
                                value: projectId
                                    ? String(projectId)
                                    : organizationId
                                      ? String(organizationId)
                                      : null,
                            },
                        },
                    },
                ],
            },
        },
        metrics: [{ name: 'totalUsers' }],
    });
}

export function gaGetDownloadActivityReport({ projectId, organizationId }) {
    return httpGa.post(':runReport', {
        dateRanges: [
            {
                startDate: '2024-06-01',
                endDate: 'today',
            },
        ],
        dimensions: [{ name: 'eventName' }, { name: 'customEvent:file_name' }],
        dimensionFilter: {
            andGroup: {
                expressions: [
                    {
                        filter: {
                            fieldName: 'eventName',
                            stringFilter: {
                                matchType: 'EXACT',
                                value: 'download_asset',
                            },
                        },
                    },
                    {
                        filter: {
                            fieldName: 'customEvent:file_type',
                            stringFilter: {
                                matchType: 'EXACT',
                                value: 'ZIP',
                            },
                        },
                    },
                    {
                        notExpression: {
                            filter: {
                                fieldName: 'customEvent:file_name',
                                stringFilter: {
                                    matchType: 'EXACT',
                                    value: '(not set)',
                                    caseSensitive: true,
                                },
                            },
                        },
                    },
                    {
                        filter: {
                            fieldName: projectId
                                ? 'customEvent:project_id'
                                : organizationId
                                  ? 'customEvent:organization_id'
                                  : null,
                            stringFilter: {
                                matchType: 'EXACT',
                                value: projectId
                                    ? String(projectId)
                                    : organizationId
                                      ? String(organizationId)
                                      : null,
                            },
                        },
                    },
                ],
            },
        },
        metrics: [{ name: 'eventCount' }],
        limit: 5,
    });
}

export function gaGetUserTypeCountsReport({ projectId, organizationId }) {
    return httpGa.post(':runReport', {
        dateRanges: [
            {
                startDate: '2024-06-01',
                endDate: 'today',
            },
        ],
        dimensions: [
            { name: 'customEvent:status' },
            { name: 'customEvent:user_type' },
        ],
        dimensionFilter: {
            andGroup: {
                expressions: [
                    {
                        notExpression: {
                            filter: {
                                fieldName: 'customEvent:status',
                                stringFilter: {
                                    matchType: 'EXACT',
                                    value: '(not set)',
                                    caseSensitive: true,
                                },
                            },
                        },
                    },
                    {
                        notExpression: {
                            filter: {
                                fieldName: 'customEvent:user_type',
                                stringFilter: {
                                    matchType: 'EXACT',
                                    value: '(not set)',
                                    caseSensitive: true,
                                },
                            },
                        },
                    },
                    {
                        filter: {
                            fieldName: projectId
                                ? 'customEvent:project_id'
                                : organizationId
                                  ? 'customEvent:organization_id'
                                  : null,
                            stringFilter: {
                                matchType: 'EXACT',
                                value: projectId
                                    ? String(projectId)
                                    : organizationId
                                      ? String(organizationId)
                                      : null,
                            },
                        },
                    },
                ],
            },
        },
        metrics: [{ name: 'totalUsers' }, { name: 'activeUsers' }],
    });
}

export function gaGetUserEngagementReport({ projectId, organizationId }) {
    // get current month range from beginning to today in YYYY-MM-DD format
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const currentMonthStartDate = `${year}-${month}-01`;

    // get last month range from beginning to end in YYYY-MM-DD format
    const lastMonth = month === 1 ? 12 : month - 1;
    const lastMonthYear = month === 1 ? year - 1 : year;
    const lastMonthEndDate = new Date(lastMonthYear, lastMonth, 0)
        .toISOString()
        .split('T')[0];
    const lastMonthStartDate = `${lastMonthYear}-${lastMonth}-01`;

    return httpGa.post(':runReport', {
        dateRanges: [
            {
                startDate: '2024-06-04',
                endDate: '2024-06-07',
                name: 'lastMonth',
            },
            {
                startDate: '2024-06-08',
                endDate: 'today',
                name: 'actual',
            },
            // {
            //     startDate: lastMonthStartDate,
            //     endDate: lastMonthEndDate,
            //     name: 'lastMonth',
            // },
            // {
            //     startDate: currentMonthStartDate,
            //     endDate: 'today',
            //     name: 'actual',
            // },
        ],
        dimensions: [
            // { name: 'eventName' },
            { name: 'customEvent:route_title' },
        ],
        dimensionFilter: {
            andGroup: {
                expressions: [
                    {
                        filter: {
                            fieldName: 'eventName',
                            stringFilter: {
                                matchType: 'EXACT',
                                value: 'page_view',
                            },
                        },
                    },
                    {
                        notExpression: {
                            filter: {
                                fieldName: 'customEvent:route_title',
                                stringFilter: {
                                    matchType: 'EXACT',
                                    value: '(not set)',
                                    caseSensitive: true,
                                },
                            },
                        },
                    },
                    {
                        filter: {
                            fieldName: projectId
                                ? 'customEvent:project_id'
                                : organizationId
                                  ? 'customEvent:organization_id'
                                  : null,
                            stringFilter: {
                                matchType: 'EXACT',
                                value: projectId
                                    ? String(projectId)
                                    : organizationId
                                      ? String(organizationId)
                                      : null,
                            },
                        },
                    },
                ],
            },
        },
        metrics: [{ name: 'eventCount' }],
    });
}

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            d="M12 18.5V19.38C12 21.25 11.25 22 9.37 22H4.62C4.27579 22.0005 3.93486 21.9331 3.61675 21.8016C3.29864 21.6702 3.0096 21.4772 2.76621 21.2338C2.52281 20.9904 2.32985 20.7014 2.19837 20.3833C2.06689 20.0651 1.99948 19.7242 2 19.38V14.63C2 12.75 2.75 12 4.62 12H5.5V15.5C5.5 16.2956 5.81607 17.0587 6.37868 17.6213C6.94129 18.1839 7.70435 18.5 8.5 18.5H12Z"
            fill="#00094F" />
        <path
            opacity="0.4"
            d="M17 13.5V14.37C16.9992 15.0673 16.7219 15.7358 16.2288 16.2288C15.7358 16.7219 15.0673 16.9992 14.37 17H9.62C7.75 17 7 16.25 7 14.37V9.62C6.99948 9.27579 7.06689 8.93486 7.19837 8.61675C7.32985 8.29864 7.52281 8.0096 7.76621 7.76621C8.0096 7.52281 8.29864 7.32985 8.61675 7.19837C8.93486 7.06689 9.27579 6.99948 9.62 7H10.5V10.5C10.5 11.2957 10.8161 12.0587 11.3787 12.6213C11.9413 13.1839 12.7044 13.5 13.5 13.5H17Z"
            fill="#00094F" />
        <path
            d="M22 4.62V9.37C22 11.25 21.25 12 19.37 12H14.62C12.75 12 12 11.25 12 9.37V4.62C12 2.75 12.75 2 14.62 2H19.37C21.25 2 22 2.75 22 4.62Z"
            fill="#00094F" />
    </svg>
</template>

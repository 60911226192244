<template>
    <div class="notifs-container">
        <header>
            <h3>Notifications</h3>
            <button @click="markAllAsRead">Mark all as read</button>
        </header>
        <ul
            v-if="notifs.length > 0"
            class="content">
            <li
                v-for="notif in notifs"
                :key="notif.id"
                @click="markAsRead(notif.id)">
                <div :class="{ disc: !notif.read_at }">
                    <h4>{{ notif.title }}:&nbsp;</h4>
                    <p>{{ notif.message }}</p>
                </div>
                <span>{{ formatRelativeTime(notif.date) }}</span>
                <hr />
            </li>
        </ul>
        <p
            class="no-notif"
            v-else>
            No notifications yet.
        </p>
    </div>
</template>

<script setup>
const props = defineProps({
    notifs: {
        type: Array,
        required: true,
    },
});

const emits = defineEmits(['markAsRead']);

function markAsRead(notifId) {
    const foundNotif = props.notifs.find(n => n.id === notifId);
    if (foundNotif.read_at === null) emits('markAsRead', notifId);
}
function markAllAsRead() {
    if (props.notifs.some(n => n.read_at === null)) emits('markAsRead', null);
}

function formatRelativeTime(date) {
    const now = new Date();
    const notifDate = new Date(date);
    const diffInSeconds = Math.floor((now - notifDate) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInSeconds < 60) {
        return 'Now';
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes}m ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours}h ago`;
    } else {
        return notifDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        });
    }
}
</script>

<style lang="scss" scoped>
.notifs-container {
    width: 500px;
    border-radius: 8px;
    border: #d9d9d9 solid 1px;
    background-color: #fff;
    &::before {
        content: '';
        position: absolute;
        top: -18px;
        left: 63%;
        width: 35px;
        height: 35px;
        border-left: 1px solid #d9d9d9;
        border-top: 1px solid #d9d9d9;
        background: linear-gradient(135deg, white 50%, transparent 50%);
        z-index: 10;
        rotate: 45deg;
    }
    header {
        background-color: #fff;
        position: sticky;
        top: 0px;
        padding: 24px 24px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #d9d9d9;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        h3 {
            font-weight: 700;
        }
        button {
            color: rgb(var(--gray-300));
        }
    }
    .content {
        overflow-y: auto;
        max-height: 380px;
        li {
            padding-top: 8px;
            margin-inline: 48px;
            cursor: pointer;
            div {
                margin-bottom: 4px;
                position: relative;
                &.disc::before {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: -24px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: rgb(var(--blue));
                }
                h4 {
                    font-weight: 700;
                    display: inline-block;
                }
                p {
                    font-size: 14px;
                    display: inline-block;
                }
            }
            span {
                font-size: 14px;
                color: rgb(var(--gray-400));
            }
            hr {
                margin-top: 14px;
                height: 1px;
                background-color: rgb(var(--gray-300));
            }
            &:first-child {
                padding-top: 20px;
            }
            &:last-child {
                padding-bottom: 20px;
                hr {
                    display: none;
                }
            }
        }
    }
    .no-notif {
        text-align: center;
        margin: 32px 0;
    }
}
</style>

<template>
    <div class="content">
        <h5 :style="{ color: textColor }">
            {{ toastProps?.data.message }}
        </h5>
        <button
            v-if="
                props.toastProps?.type === 'success' &&
                toastProps?.data.requestMethod === 'delete'
            "
            class="undo"
            @click="onUndo">
            <i
                style="font-size: 18px; color: rgb(var(--white) / 0.5)"
                class="iconsax"
                icon-name="undo"></i>
            <span>Undo</span>
        </button>
        <div class="divider"></div>
        <button
            @click="
                $event => {
                    closeToast && closeToast($event);
                }
            ">
            <i
                style="font-size: 18px; color: rgb(var(--white) / 0.5)"
                class="iconsax"
                icon-name="x"></i>
        </button>
    </div>
</template>

<script setup>
import { useToastStore } from '@/store/toast';
import { computed } from 'vue';

const props = defineProps({
    closeToast: Function,
    toastProps: Object,
});

const toastStore = useToastStore();

const textColor = computed(() => {
    switch (props.toastProps?.type) {
        case 'success':
            return 'rgb(var(--green))';
        case 'warning':
            return 'rgb(var(--orange))';
        case 'error':
            return 'rgb(var(--red))';
        default:
            return 'rgb(var(--white))';
    }
});

function onUndo() {
    toastStore.runUndoFunction();
    props.closeToast();
}
</script>

<style lang="scss">
:root {
    --toastify-color-light: #fff;
    --toastify-color-success: rgb(var(--green));
    --toastify-color-warning: rgb(var(--orange));
    --toastify-color-error: rgb(var(--red));
    --toastify-text-color-success: rgb(var(--green));
    --toastify-text-color-warning: rgb(var(--orange));
    --toastify-text-color-error: rgb(var(--red));
    --toastify-toast-min-height: 50px;
}

.app-toast {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > div {
        border-radius: 12px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
        font-size: 12px;
        font-weight: 600;
        font-family: 'Nunito', sans-serif;
        cursor: default;
        margin-bottom: 0px;
        padding: 0 16px;
        width: fit-content;
    }

    .content {
        display: flex;
        align-items: center;
        h5 {
            flex-grow: 1;
            width: max-content;
        }
        .undo {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            width: 72px;
            height: 34px;
            background-color: rgba(var(--white) / 0.1);
            flex-shrink: 0;
            border-radius: 6px;
            margin-left: 8px;
        }
        .divider {
            width: 0px;
            height: 22px;
            border-right: 1px solid rgb(var(--white) / 0.2);
            margin: 0 20px 0 12px;
        }
    }
}
</style>

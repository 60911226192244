<template>
    <svg
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.46484 2V5"
            stroke="#00094F"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M16.5352 2V5"
            stroke="#00094F"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            opacity="0.4"
            d="M3.92529 9.08997H21.0749"
            stroke="#00094F"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M21.5793 8.5V17C21.5793 20 20.0661 22 16.5353 22H8.46489C4.93409 22 3.4209 20 3.4209 17V8.5C3.4209 5.5 4.93409 3.5 8.46489 3.5H16.5353C20.0661 3.5 21.5793 5.5 21.5793 8.5Z"
            stroke="#00094F"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            opacity="0.4"
            d="M12.4954 13.7H12.5045"
            stroke="#00094F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            opacity="0.4"
            d="M8.76204 13.7H8.7711"
            stroke="#00094F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            opacity="0.4"
            d="M8.76172 16.7H8.7707"
            stroke="#00094F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>

<style scoped lang="scss">
svg {
    aspect-ratio: 24 / 25;
}
</style>

import { defineStore } from 'pinia';

export const useBreadcrumbStore = defineStore({
    id: 'breadcrumb',
    state: () => ({
        breadcrumbs: [],
    }),
    actions: {
        set(breadcrumbs) {
            this.breadcrumbs = breadcrumbs;
        },
        push(breadcrumb) {
            this.breadcrumbs.push(breadcrumb);
        },
        pop() {
            this.breadcrumbs.pop();
        },
        replace(payload) {
            const index = this.breadcrumbs.findIndex(breadcrumb => {
                return breadcrumb.text === payload.find;
            });

            if (index) {
                this.breadcrumbs.splice(index, 1, payload.replace);
            }
        },
        empty() {
            this.breadcrumbs = [];
        },
    },
});

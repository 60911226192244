<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none">
        <path
            d="M12.9202 0.180176H6.69024H1.08024C0.120237 0.180176 -0.359763 1.34018 0.320237 2.02018L5.50024 7.20018C6.33024 8.03018 7.68024 8.03018 8.51024 7.20018L10.4802 5.23018L13.6902 2.02018C14.3602 1.34018 13.8802 0.180176 12.9202 0.180176Z"
            fill="#2D68FF" />
    </svg>
</template>

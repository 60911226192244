<template>
    <div class="app">
        <MainSidebar
            v-if="$route.meta.layout == 'default'"
            :is-sidebar-open="isSidebarOpen"
            @close-sidebar="closeSidebar" />
        <div
            class="main-section"
            :class="{
                'p-0': $route.meta.layout != 'default',
                'main-section1': !$route.meta.mainSidebar,
                'main-section2': $route.meta.mainSidebar,
            }">
            <MainHeader
                v-if="$route.meta.layout == 'default'"
                @open-sidebar="openSidebar" />
            <main>
                <router-view />
            </main>
        </div>
    </div>
    <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import OverlayLoader from '@/components/base/overlay/OverlayLoader.vue';
import MainHeader from '@/components/base/MainHeader';
import MainSidebar from '@/components/base/MainSidebar.vue';
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from '@/store/loading.js';
import { gaGetAccessKey } from '@/services/axios/ga/ga.service';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();
const store = useStore();

const isLoading = computed(() => {
    return store.isLoading;
});

const getExpirationTime = () => {
    const expiresIn = parseInt(localStorage.getItem('expires_in'), 10);
    return expiresIn ? expiresIn * 1000 : 1000 * 60 * 59; // default to 59 minutes if not set
};

const startTokenTimer = async () => {
    try {
        const lastTokenTime = parseInt(
            localStorage.getItem('lastTokenTime'),
            10
        );
        const now = Date.now();
        const expirationTime = getExpirationTime();

        if (!lastTokenTime || now - lastTokenTime >= expirationTime) {
            await refreshToken();
            setTokenInterval(expirationTime);
        } else {
            const remainingTime = expirationTime - (now - lastTokenTime);
            setTokenInterval(remainingTime);
        }
    } catch (error) {
        console.error(error);
    }
};

const setTokenInterval = intervalTime => {
    try {
        clearInterval(localStorage.getItem('tokenIntervalId'));

        const intervalId = setInterval(async () => {
            await refreshToken();
        }, intervalTime);

        localStorage.setItem('tokenIntervalId', intervalId);
    } catch (error) {
        console.error(error);
    }
};

const refreshToken = async () => {
    try {
        const response = await gaGetAccessKey();
        localStorage.setItem('ga_access_key', response.data.data.access_token);
        localStorage.setItem('lastTokenTime', Date.now());
        localStorage.setItem('expires_in', response.data.data.expires_in);
    } catch (error) {
        console.error(error, 1);
    }
};

onMounted(() => {
    if (store.numberOfAjaxCAllPending == 0) {
        store.mutationer({ isLoading: false });
    }
    const token = cookies.get('token');

    if (localStorage.getItem('ga_access_key') && token) {
        startTokenTimer();
    }
});

onBeforeUnmount(() => {
    clearInterval(localStorage.getItem('tokenIntervalId'));
});

const isSidebarOpen = ref(false);
function openSidebar() {
    isSidebarOpen.value = true;
}
function closeSidebar() {
    isSidebarOpen.value = false;
}
</script>

<style scoped lang="scss">
.app {
    display: flex;
    background-color: rgb(var(--gray-100));
    .main-section {
        transition: 0.4s ease all;
        width: 100%;
        @media (min-width: 1024px) {
            padding-left: 356px;
        }
    }
    .main-section1 {
        @media (min-width: 1024px) {
            padding-left: 356px;
        }
    }
    .main-section2 {
        min-height: 100vh;
        @media (min-width: 1024px) {
            padding-left: 100px;
        }
    }
}
</style>
